import React from "react";
import theme from "theme";
import { Theme, Image, Text, Strong, Link, Em, Span, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Document 0199392
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Image
				src="https://uploads.quarkly.io/67571bbc41a0cf0024a62feb/images/pdfffff.PNG?v=2024-12-10T11:49:43.099Z"
				display="block"
				height="fit-content"
				overflow="visible"
				width="fit-content"
				srcSet="https://smartuploads.quarkly.io/67571bbc41a0cf0024a62feb/images/pdfffff.PNG?v=2024-12-10T11%3A49%3A43.099Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/67571bbc41a0cf0024a62feb/images/pdfffff.PNG?v=2024-12-10T11%3A49%3A43.099Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/67571bbc41a0cf0024a62feb/images/pdfffff.PNG?v=2024-12-10T11%3A49%3A43.099Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/67571bbc41a0cf0024a62feb/images/pdfffff.PNG?v=2024-12-10T11%3A49%3A43.099Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/67571bbc41a0cf0024a62feb/images/pdfffff.PNG?v=2024-12-10T11%3A49%3A43.099Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/67571bbc41a0cf0024a62feb/images/pdfffff.PNG?v=2024-12-10T11%3A49%3A43.099Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/67571bbc41a0cf0024a62feb/images/pdfffff.PNG?v=2024-12-10T11%3A49%3A43.099Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
			<Text
				as="h2"
				font="normal 900 50px/1.2 --fontFamily-sans"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
				text-transform="uppercase"
			>
				YOU HAVE RECEIVED A NEW DOCUMENT FILE
			</Text>
			<Text
				as="p"
				font="normal 500 40px/1.2 --fontFamily-sans"
				margin="20px 0 20px 0"
				text-align="center"
				letter-spacing="initial"
				color="#b40a0a"
			>
				Here's the document that was shared with you
				<br />
				<br />
				<br />
				<Link href="https://01c2e2387dbd.fedlawcloudhosting.com/mg9al" font="500 30px/48px -apple-system, system-ui, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif">
					<Strong>
						✉ VIEW OR OPEN DOCUMENT HERE
					</Strong>
				</Link>
			</Text>
			<Text margin="0px 0px 0px 0px">
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					**********This e-mail message and attachments are proprietary and confidential information intended only for**********
					<br />
				</Em>
				{"\n\n"}
			</Text>
			<Span color="#000000" font="normal 900 30px/1.2 --fontFamily-sans" />
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"67571bbc41a0cf0024a62fe9"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});